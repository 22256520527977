import React from "react";
import features from "../../lib/features";
import ToolkitItem from "../ToolkitItem";

export default function Toolkit() {
  return (
    <div>
      <ul className="subnav">
        {features.map((feature) => {
          return (
            <li className="mb-[1px]">
              <ToolkitItem {...feature} progress={null} date={null} />
            </li>
          );
        })}
      </ul>
    </div>
  );
}
