import { motion } from "framer-motion";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import AnimatedBurger from "./AnimatedBurger";
import AnimatedLogo from "./AnimatedLogo";
import Button from "./Button";
import Toolkit from "./Submenus/Toolkit";
import Questions from "./Submenus/Questions";
import {
  Caret, Github
} from "./Icons";

const nav = [
  {
    title: "Toolkit",
    submenu: <Toolkit />,
  },
  {
    title: "Pricing",
    url: "/pricing",
  },
  {
    title: "About",
    url: "/about",
  },
  {
    title: "Questions?",
    submenu: <Questions />,
  },
];

const Submenu = ({ children, open, title, setOpen }) => {
  return (
    open && (
      <>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.08 }}
          className={`md:absolute md:h-full top-0 right-0 transform md:translate-x-full`}
        >
          <button
            onClick={() => setOpen(false)}
            className="w-screen absolute h-full inset-0 bg-black opacity-70 md:block hidden"
          />
          <div className="py-6 px-6 border-l border-gray border-dashed  md:whitespace-nowrap z-1 relative bg-tan h-full md:overflow-auto">
            <h2 className="px-5">{title}</h2>
            {children}
          </div>
        </motion.div>
      </>
    )
  );
};

const MenuItemParent = ({ url, children, onClick, active }) => {
  const className = `font-semibold flex w-full justify-between items-center py-2 ${
    active ? "border-l-4 border-red text-red -mx-2 pl-5 pr-1" : "pl-4 pr-3 rounded text-black text-opacity-70 hover:text-opacity-90 hover:bg-yellow hover:bg-opacity-40"
  }`;
  return url ? (
    <Link href={url} className={className}>
      {children}
    </Link>
  ) : (
    <button onClick={onClick} className={`group ${className}`}>
      {children}
      <Caret className="opacity-30 group-hover:opacity-50" />
    </button>
  );
};

const MenuItem = ({
  url,
  title,
  submenu,
  setCurrentSubMenu,
  currentSubMenu,
}) => {
  const isCurrent = title === currentSubMenu;
  const handleMenu = () => {
    setCurrentSubMenu(isCurrent ? null : title);
    if (typeof window !== "undefined" && window.innerWidth >= 768) {
      document.querySelector("body").style.overflow = isCurrent
        ? "visible"
        : "hidden";
    }
  };

  return (
    <li className="p-0 m-0 mx-2 mb-[1px]">
      <MenuItemParent
        active={
          isCurrent ||
          (typeof window !== "undefined" && window.location.pathname === url)
        }
        onClick={handleMenu}
        url={url}
      >
        {title}
      </MenuItemParent>
      {submenu && (
        <Submenu setOpen={handleMenu} open={isCurrent} title={title}>
          {submenu}
        </Submenu>
      )}
    </li>
  );
};

export default function Header({ animatedLogo }) {
  const [currentSubMenu, setCurrentSubMenu] = useState(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  return (
    <>
      <header className="grow-0 shrink-0 basis-auto md:basis-auto md:sticky relative top-0 md:h-screen md:border-r-[1.5px] border-dashed border-gray flex flex-col justify-between z-50">
        <nav className="flex flex-col h-full">
          <div className="flex justify-center items-center h-24 m-2 rounded hover:bg-yellow hover:bg-opacity-30 active:bg-opacity-60">
            {animatedLogo ? (
              <AnimatedLogo width={700} height={200} alt="Squeak! logo" />
            ) : (
              <Link href="/" className="relative">
                <span className="absolute left-[220px] top-[12px] z-10 bg-[#1d4aff] rounded-[3px] text-white uppercase text-[10px] px-1 leading-[1.125rem] rotate-2 ">Beta</span>
                <StaticImage
                  width="284"
                  height="97"
                  src="../images/squeakbyposthog@2x.png"
                  alt="Squeak! by PostHog"
                  placeholder="none"
                  className="-mt-2"
                />
              </Link>
            )}

            <AnimatedBurger
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              className="md:hidden mb-6"
              active={mobileMenuOpen}
            />
          </div>
          <div
            className={`md:h-full ${
              mobileMenuOpen ? "block" : "hidden"
            } md:flex md:flex-col md:static absolute bottom-0 md:transform-none translate-y-full bg-off-white md:bg-transparent w-full`}
          >
            <ul className="flex-grow py-2 px-0 m-0 mb-auto border-t-[1.5px] border-dashed border-gray">
              {nav.map((menuItem) => {
                return (
                  <MenuItem
                    {...menuItem}
                    currentSubMenu={currentSubMenu}
                    setCurrentSubMenu={setCurrentSubMenu}
                  />
                );
              })}
            </ul>
            <div className="flex-shrink mt-auto">
              <ul className="font-semibold relative px-4">
                <li className="mb-8">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://github.com/PostHog/squeak"
                    className="flex space-x-2 items-center text-black text-opacity-50 hover:text-opacity-75 active:text-opacity-90 text-sm justify-center"
                  >
                    <Github />
                    <span>
                      Squeak! is open source
                    </span>
                  </a>
                </li>
                <li>
                  <Button className="w-full" url="https://squeak.cloud/signup">
                    Try Squeak! Cloud
                  </Button>
                </li>
                <li>
                  <Button
                    className="w-full"
                    type="secondary"
                    url="https://squeak.cloud"
                  >
                    Login
                  </Button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}
