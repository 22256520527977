import { Link } from "gatsby";
import React from "react";

const progessColors = {
  percentage: {
    beta: "bg-green",
    alpha: "bg-orange",
  },
  label: {
    beta: "text-green",
    alpha: "text-orange",
  },
};

const Parent = ({ url, children }) => {
  const className = "rounded py-2 px-6 flex";
  if (url) {
    return (
      <Link className={`hover:bg-gray hover:bg-opacity-40 ${className}`} to={url}>
        {children}
      </Link>
    );
  } else {
    return <div className={className}>{children}</div>;
  }
};

export default function Feature({ url, label, body, progress, date }) {
  return (
    <Parent url={url}>
      <div className="flex-grow">
        <h3 className="font-bold text-xl mt-2 mb-0 pb-0">{label}</h3>
        <p className="text-sm font-normal mt-0">{body}</p>
      </div>
      {progress && (
        <div className="flex-shrink w-2/6 flex items-center space-x-2">
          <div className="w-32 h-3 rounded-sm bg-gray relative overflow-hidden">
            <div
              style={{ width: progress?.percentage }}
              className={`absolute left-0 h-full ${
                progessColors.percentage[progress?.label.toLowerCase()] || ""
              }`}
            />
          </div>
          <span
            className={`font-bold text-xs uppercase ${
              progessColors.label[progress?.label.toLowerCase()] || ""
            }`}
          >
            {progress?.label}
          </span>
        </div>
      )}
      {date && (
        <div className="flex-shrink w-2/6 flex items-center space-x-2">
          <span className="text-sm text-opacity-50">{date}</span>
        </div>
      )}
    </Parent>
  );
}
