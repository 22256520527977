export default [
  {
    label: "Q&A.js",
    body: "Add threaded discussions to any page of your docs or website",
    progress: {
      label: "Beta",
      percentage: "80%",
    },
    url: "/toolkit/qna.js",
  },
  {
    label: "Discussion Warehouse",
    body: "A repository to manage shared knowledge threads from Slack, Q&A.js, and other sources",
    progress: {
      label: "Beta",
      percentage: "70%",
    },
    url: "/toolkit/discussion-warehouse",
  },
  {
    label: "Import Slack Threads",
    body: "Liberate product-related discussions from Slack and make them discoverable by search engines",
    progress: {
      label: "Alpha",
      percentage: "60%",
    },
    url: "/toolkit/import-slack-threads",
  },
  {
    label: "Auth 2.0",
    body: "OAuth, social logins",
    date: "Summer 2022",
  },
  {
    label: "Dynamic Open Graph Images",
    body: "A custom social media graphic for every webpage across your docs or website",
    date: "Summer 2022",
  },
  {
    label: "Community Profiles",
    body: "Bios, badges, activity feed, leaderboards, lists/directories",
    date: "Summer 2022",
  },
  {
    label: "Feature voting",
    body: "Submit & upvote ideas",
    date: "Summer 2022",
  },
  {
    label: "Knowledge Repository",
    body: "Lightweight, optimized content platform for docs, articles, guides, blogs",
    date: "Fall 2022",
  },
  {
    label: "Knowledge Search",
    body: "Searchable interface for Discussions, Knowledge",
    date: "Fall 2022",
  },
  {
    label: "Page Builder",
    body: "MDX + Storybook mashup w/ collections, lists, menus",
    date: "Fall 2022",
  },
  {
    label: "Dynamic Pages",
    body: "Personalized content for each visitor by IP, UTM codes, or CRM",
    date: "Winter 2022",
  },
  {
    label: "Marketplace",
    body: "Allow community to make money from services for the platform",
    date: "Spring 2023",
  },
];
