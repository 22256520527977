import { Link } from "gatsby";
import React from "react";

const buttonClasses = {
  primary: `bg-black bg-opacity-80 border-2 border-solid border-black text-white hover:bg-opacity-100`,
  secondary: `border-2 border-solid border-gray bg-transparent text-black text-opacity-90 hover:border-black hover:border-opacity-30 hover:text-opacity-100`,
};

export default function Button({
  url,
  children,
  className = "",
  type = "primary",
  icon,
}) {
  return (
    <Link
      to={url}
      className={`py-2 px-6 font-bold text-base inline-flex justify-center items-center rounded-md leading-normal ${
        buttonClasses[type] || ""
      } ${className}`}
    >
      {icon && <span className="mr-2">{icon}</span>}
      <span>{children}</span>
    </Link>
  );
}
