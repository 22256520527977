import React from "react";

export default function Section({ children, className = "", id = "" }) {
  return (
    <section
      id={id}
      className={`max-w-[1200px] w-full px-4 md:px-8 lg:px-12 xl:px-16 2xl:px-24 mx-auto mb-16 transition-all duration-300 ${className}`}
    >
      {children}
    </section>
  );
}
