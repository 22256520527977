import React from "react";
import support from "../../lib/support";
import QuestionsItem from "../QuestionsItem";

export default function Questions() {
  return (
    <div>
      <ul className="subnav">
        {support.map((feature) => {
          return (
            <li className="mb-[1px]">
              <QuestionsItem {...feature} progress={null} date={null} />
            </li>
          );
        })}
      </ul>
    </div>
  );
}
