import React, { useEffect } from "react";
import Footer from "./Footer";
import Header from "./Header";
import SEO from "./SEO";

export default function Layout({
  children,
  animatedLogo,
  title,
  description,
  image,
}) {
  useEffect(() => {
    if (typeof window !== "undefined") {
      document.querySelector("body").style.overflow = "visible";
    }
  }, []);
  return (
    <>
      <SEO title={title} description={description} image={image} />
      <div className="md:flex">
        <Header animatedLogo={animatedLogo} />
        <div className="flex-1 flex flex-col mb-auto">
          <main className="flex-1">{children}</main>
          <Footer />
        </div>
      </div>
    </>
  );
}
