import React from "react";
import { Link } from "gatsby";
import Section from "../components/Section";
import { useForm, ValidationError } from '@formspree/react';

function EmailSignupForm() {
  const [state, handleSubmit] = useForm("xqknglpn");
  if (state.succeeded) {
      return <div className="border-2 border-solid border-green py-4 px-8 rounded-xl"><h3 className="mt-3 mb-0">Thanks for joining!</h3><p className="mt-1">Somewhere a hedgehog just did a backflip because of you.</p></div>;
  }
  return (
      <form onSubmit={handleSubmit}>
      
      <label htmlFor="email" className="text-2xl font-bold block mb-3">
        Get updates about Squeak!
      </label>
      <div className="flex flex-col md:flex-row md:items-end md:space-x-3 space-y-3">
        <input
          id="email"
          type="email" 
          name="email"
          placeholder="Email address..."
          className="bg-transparent py-2 text-lg border-b-2 border-solid outline-none border-black/40 focus:border-black/100 w-full md:w-64"
        />
        <button type="submit" disabled={state.submitting} className="py-2 px-6 font-bold text-base inline-flex justify-center items-center rounded-md leading-normal bg-black bg-opacity-80 border-2 border-solid border-black text-white hover:bg-opacity-100">
          Subscribe
        </button>
      </div>

      <ValidationError 
          prefix="Email" 
          field="email"
          errors={state.errors}
          className="text-red pt-2 font-bold text-base"
        />
    </form>
  );
}

export default function Footer() {
  return (
    <>
      <div className="border-t border-dashed border-gray py-8">
        <Section className="!my-8">
          <EmailSignupForm />
        </Section>
      </div>
      <footer className="py-8 border-t border-dashed border-gray">
        <Section className="grid grid-cols-2 lg:grid-cols-4 space-y-8 lg:space-y-0 lg:space-x-4 text-base 2xl:max-w-full">
          <nav>
            <ul className="space-y-3">
              <li>
                <Link href="/">Home</Link>
              </li>
              <li>
                <Link href="/pricing">Pricing</Link>
              </li>
              <li>
                <a href="https://github.com/orgs/PostHog/projects/40" target="_blank" rel="noreferrer" className="after:content-['_↗']">Roadmap</a>
              </li>
              <li>
                <Link href="/about">About</Link>
              </li>
              <li>
                <Link href="/questions">Questions?</Link>
              </li>
            </ul>
          </nav>

          <nav>
            <ul>
              <li>
                <h4 className="text-black text-opacity-60 text-sm">Toolkit</h4>
              </li>
              <li>
                <Link href="/toolkit/qna.js">Q&amp;A.js</Link>
              </li>
              <li>
                <Link href="/toolkit/discussion-warehouse">Discussion Warehouse</Link>
              </li>
              <li>
                <Link href="/toolkit/import-slack-threads">Import Slack Threads</Link>
              </li>
            </ul>
          </nav>

          <nav>
            <ul>
              <li>
                <h4 className="text-black text-opacity-60 text-sm">Resources</h4>
              </li>
              <li>
                <a href="https://github.com/orgs/PostHog/squeak" target="_blank" rel="noreferrer" className="after:content-['_↗']">GitHub</a>
              </li>
              <li>
                <a href="https://github.com/PostHog/squeak/wiki" target="_blank" rel="noreferrer" className="after:content-['_↗']">Docs</a>
              </li>
            </ul>
          </nav>

          <nav>
            <ul>
              <li>
                <h4 className="text-black text-opacity-60 text-sm">Legal</h4>
              </li>
              <li>
                <a href="https://posthog.com/privacy" target="_blank" rel="noreferrer" className="after:content-['_↗']">Privacy</a>
              </li>
              <li>
                <a href="https://posthog.com/terms" target="_blank" rel="noreferrer" className="after:content-['_↗']">Terms of use</a>
              </li>
            </ul>
          </nav>

        </Section>
      </footer>
    </>
  );
}
