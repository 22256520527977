export default [
  {
    label: "About Squeak!?",
    body: "General questions about using Squeak! or how to contribute",
    url: "/questions",
  },
  {
    label: "Q&A.js support",
    body: "Install help, bug reports, etc",
    url: "/questions/qna.js",
  },
];
